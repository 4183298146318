import React, { useContext } from 'react';
import { RoleContext } from './RoleProvider';



const useSecuredFetch = () => {
    const { ensureSession, token } = useContext(RoleContext);

    const secureFetch = async (url: string, verb: string, bodyStr?: string) => {
        const result = await ensureSession();
        if(!result){
            await ensureSession();
        }
        const configObject: any = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "wowie": token,
            },
            method: verb
        };

        if(verb !== "GET" && bodyStr){
            configObject.body =  bodyStr
        }
        const response = await fetch(url, configObject );
        return response;
    }

    return secureFetch;
}

export default useSecuredFetch;