import React, { useContext, useEffect, useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import {
  Header,
  Select,
  SelectProps,
  Form,
  FormField,
  Textarea,
  Input,
} from "@amzn/awsui-components-react";
import { ModalContext } from "./useModal";
import { RiskContext } from "./RiskProvider";
import { prettyDateString } from "../utils";

const EditSubRiskModal = () => {
  const { isShowing, hideModals } = useContext(
    ModalContext
  );
  const { dispatch, state, updateSubRisk, createSubRisk } = useContext(RiskContext);
  const { selectedSubRisk } = state;

  const [selectedSubRiskName, setSelectedSubRiskName] = useState("");
  const [textareaGDValue, setTextareaGDValue] = useState("");
  const [textareaSCValue, setTextareaSCValue] = useState("");
  const [reportable, setReportable] = useState<SelectProps.Option>({});
  const [selectedStatus, setSelectedStatus] = useState<SelectProps.Option>({});
  const [brandNewSubRisk, setBrandNewSubRisk] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const reportableOptions = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];

  const statusOptions = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  useEffect(() => {
    setSelectedSubRiskName(selectedSubRisk.sub_risk_name);
    setTextareaGDValue(selectedSubRisk.description);
    setTextareaSCValue(selectedSubRisk.specific_context);
    setReportable(reportableOptions.filter((e) => e.value == selectedSubRisk.reportable.toString())[0]);
    setSelectedStatus(statusOptions.filter((e) => e.value == selectedSubRisk.active_flag.toString())[0]);
    setBrandNewSubRisk(!isShowing("editSubRisk"))
  }, [selectedSubRisk.sub_risk_id, refreshCounter]);

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("editSubRisk") || isShowing("createSubRisk")}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={(_event) => hideModals()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(evt) => {
              if (brandNewSubRisk) {
                createSubRisk(state.selectedRiskCategory.risk_number, state.nextSubRiskIds[state.selectedRiskCategory.risk_number], selectedSubRiskName, parseInt(selectedStatus.value!),
                  textareaGDValue, parseInt(reportable.value!), textareaSCValue);
              } else {
                updateSubRisk(selectedSubRisk.acs_risk_dim_sk, selectedSubRiskName, parseInt(selectedStatus.value!),
                  textareaGDValue, parseInt(reportable.value!), textareaSCValue);
              }
              dispatch({Type: "SET_SELECTED_SUBRISK_TO_EMPTY", Cargo: null});
              setRefreshCounter((refreshCounter) => refreshCounter + 1);
              hideModals();
            }}>Save</Button>
          </SpaceBetween>
        </Box>
      }
      header={brandNewSubRisk ? "Add new risk" : "Edit Risk entry"}
    >
      <Form header={<Header variant="h2">Risk</Header>}>
        <FormField label="Risk Name">
         <Input
           onChange={({ detail }) => setSelectedSubRiskName(detail.value)}
            value={selectedSubRiskName}
          />

        </FormField>
        <FormField label="General Description">
          <Textarea
            onChange={({ detail }) => setTextareaGDValue(detail.value)}
            value={textareaGDValue}
            placeholder="Add description of the risk here."
          ></Textarea>
        </FormField>

        <FormField label="Specific Context">
          <Textarea
            onChange={({ detail }) => setTextareaSCValue(detail.value)}
            value={textareaSCValue}
            placeholder="Add Specific context here."
          ></Textarea>
        </FormField>
        <FormField label="Reportable:">
          <Select
            selectedOption={reportable}
            onChange={({ detail }) => setReportable(detail.selectedOption)}
            options={reportableOptions}
          />
        </FormField>
        <FormField label="Status:">
          <Select
            selectedOption={selectedStatus}
            onChange={({ detail }) => setSelectedStatus(detail.selectedOption)}
            options={statusOptions}
          />
        </FormField>
        <FormField label="Last edited date:">{prettyDateString(selectedSubRisk.last_updated)}</FormField>
      </Form>
    </Modal>
  );
};

export default EditSubRiskModal;
