import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Button, Container } from "@amzn/awsui-components-react";
import { ModalContext } from "../useModal";

const ReadOnlyRiskRegisterGetStartedModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);

  return (
    <Modal
      onDismiss={() => hideModals()}
      visible={isShowing("readOnlyRiskRegisterGetStartedModal")}
    >
       <Container header={<h3 className="center">Welcome to the global risk register</h3>}>      
        <div className="control-details-modal">
        <div className="modal-body">
        The <strong>global risk register</strong> is a collection of the security risks Security Evaluations
        (SecEvals) tracks and reports globally.
        Individual risks are categorized thematically and designated a risk name based on
        common usage.
        To get started, select the risk category to view the individual risks and
        descriptions.  Select "View control set" to view the list of controls for the risk.
        <p>
        
        <strong>Risk-control set:</strong> A risk-control set is a designated group security controls that
        mitigate a given risk. A control criticality score is assigned to each, indicating the
        degree to which the control contributes to mitigating the risk. Controls with higher
        criticality scores indicate a greater role in mitigating the risk.
        </p>
        <p>
        For questions or comments please reach out to <Button href="mailto:SecEvals-Global@amazon.com" variant="inline-link">SecEvals-Global@amazon.com</Button>
        </p>
        </div>
      </div>
      </Container>

    </Modal >
  );
};

export default ReadOnlyRiskRegisterGetStartedModal;
