import React, { createContext, useContext, useEffect, useState } from 'react';
import { RoleContext } from "./RoleProvider";
import { DefinitionsContext } from "./DefinitionsProvider";
import { emptyLocation } from '../constants';
import { getBaseUrl } from "../utils";
import { EntityType, HealthCheckDBType, HealthCheckRowType } from 'src/types';
import useSecuredFetch from "./useSecuredFetch";


export type SelectedSiteContextType = {
	address: string,
	setAddress: (a: string) => void,
	aor: string,
	setAor: (a: string) => void,
	ASM: string,
	setAsm: (a: string) => void,
	carverAssessment: number,
	setCarverAssessment: (n: number) => void,
	carverAccessibility: number,
	setCarverAccessibility: (n: number) => void,
	carverCriticality: number,
	setCarverCriticality: (n: number) => void,
	carverEffect: number,
	setCarverEffect: (n: number) => void,
	carverRecognizability: number,
	setCarverRecognizability: (n: number) => void,
	carverVulnerability: number,
	setCarverVulnerability: (n: number) => void,
	carverRecoverability: number,
	setCarverRecoverability: (n: number) => void,
	city: string,
	setCity: (a: string) => void,
	confidentialOrRestricted: number,
	setConfidentialOrRestricted: (a: number) => void,
	country: string,
	setCountry: (a: string) => void,
	isActive: number,
	setIsActive: (a: number) => void,
	lastModified: string,
	setLastModified: (a: string) => void,
	dateCreated: string,
	setDateCreated: (a: string) => void,
	locationType: string,
	setLocationType: (a: string) => void,
	site: string,
	setSite: (a: string) => void,
	type: string,
	setType: (a: string) => void,
	region: string,
	setRegion: (a: string) => void,
	rsm: string,
	setRsm: (a: string) => void,
	psec: string,
	setPsec: (a: string) => void,
	protectiveGroup: string,
	setProtectiveGroup: (a: string) => void,
	state_province: string,
	setStateProvince: (a: string) => void,
	healthCheckResults: HealthCheckRowType[],
	selectedEntity: EntityType;
	setSelectedLocation: (loc: EntityType) => void;
	postChanges: (_ent: EntityType) => Promise<boolean>,
	putChanges: () => Promise<boolean>,
	isEditing: boolean,
	setIsEditing: (val: boolean) => void,
	refreshCounterForSelectedSite: number
}

const dummy: SelectedSiteContextType = {
	address: '',
	setAddress: (a: string) => undefined,
	aor: '',
	setAor: (a: string) => undefined,
	ASM: '',
	setAsm: (a: string) => undefined,
	carverAssessment: 0,
	setCarverAssessment: (n: number) => undefined,
	carverAccessibility: 0,
	setCarverAccessibility: (n: number) => undefined,
	carverCriticality: 0,
	setCarverCriticality: (n: number) => undefined,
	carverEffect: 0,
	setCarverEffect: (n: number) => undefined,
	carverRecognizability: 0,
	setCarverRecognizability: (n: number) => undefined,
	carverVulnerability: 0,
	setCarverVulnerability: (n: number) => undefined,
	carverRecoverability: 0,
	setCarverRecoverability: (n: number) => undefined,
	city: '',
	setCity: (a: string) => undefined,
	confidentialOrRestricted: -1,
	setConfidentialOrRestricted: (a: number) => undefined,
	country: '',
	setCountry: (a: string) => undefined,
	dateCreated: '',
	setDateCreated: (a: string) => undefined,
	isActive: -1,
	setIsActive: (a: number) => undefined,
	lastModified: '',
	setLastModified: (a: string) => undefined,
	locationType: '',
	setLocationType: (a: string) => undefined,
	site: '',
	setSite: (a: string) => undefined,
	type: '',
	setType: (a: string) => undefined,
	region: '',
	setRegion: (a: string) => undefined,
	rsm: '',
	setRsm: (a: string) => undefined,
	psec: '',
	setPsec: (a: string) => undefined,
	protectiveGroup: '',
	setProtectiveGroup: (a: string) => undefined,
	state_province: '',
	setStateProvince: (a: string) => undefined,
	healthCheckResults: [],
	selectedEntity: emptyLocation,
	setSelectedLocation: (_location: EntityType) => { },
	putChanges: () => (Promise.resolve(false)),
	postChanges: (_ent: EntityType) => (Promise.resolve(false)),
	isEditing: false,
	setIsEditing: (val: boolean) => undefined,
	refreshCounterForSelectedSite: 0

}

const SelectedSiteContext = createContext<SelectedSiteContextType>(dummy);

const SelectedSiteContextProvider = (props: { children: JSX.Element }) => {
	const secureFetch = useSecuredFetch();
	const [selectedEntity, setSelectedEntity] = useState(emptyLocation);
	const {
		address: addressStartingValue,
		aor: aorStartingValue,
		ASM: asmStartingValue,
		carver_assessment: carverAssessmentStartingValue,
		carver_accessibility: carverAccessibilityStartingValue,
		carver_criticality: carverCriticalityStartingValue,
		carver_effect: carverEffectStartingValue,
		carver_recognizability: carverRecognizabilityStartingValue,
		carver_vulnerability: carverVulnerabilityStartingValue,
		carver_recoverability: carverRecoverabilityStartingValue,
		city: cityStartingValue,
		dateCreated: dateCreatedStartingValue,
		confidentialOrRestricted: confidentialOrRestrictedStartingValue,
		country: countryStartingValue,
		isActive: isActiveStartingValue,
		lastModified: lastModifiedStartingValue,
		locationType: locationTypeStartingValue,
		site: siteStartingValue,
		type: typeStartingValue,
		region: regionStartingValue,
		RSM: rsmStartingValue,
		PSEC: psecStartingValue,
		protectiveGroup: protectiveGroupStartingValue,
		state_province: state_provinceStartingValue,

	} = selectedEntity;

	const [isEditing, setIsEditing] = useState(false);

	const [address, setAddress] = useState(addressStartingValue);
	const [aor, setAor] = useState(addressStartingValue);
	const [ASM, setAsm] = useState(asmStartingValue);
	const [carverAssessment, setCarverAssessment] = useState(carverAssessmentStartingValue);
	const [carverAccessibility, setCarverAccessibility] = useState(carverAccessibilityStartingValue);
	const [carverCriticality, setCarverCriticality] = useState(carverCriticalityStartingValue);
	const [carverEffect, setCarverEffect] = useState(carverEffectStartingValue);
	const [carverRecognizability, setCarverRecognizability] = useState(carverRecognizabilityStartingValue);
	const [carverVulnerability, setCarverVulnerability] = useState(carverVulnerabilityStartingValue);
	const [carverRecoverability, setCarverRecoverability] = useState(carverRecoverabilityStartingValue);

	const [city, setCity] = useState(cityStartingValue);
	const [confidentialOrRestricted, setConfidentialOrRestricted] = useState(confidentialOrRestrictedStartingValue);
	const [country, setCountry] = useState(countryStartingValue);
	const [isActive, setIsActive] = useState(isActiveStartingValue);
	const [locationType, setLocationType] = useState(locationTypeStartingValue);
	const [site, setSite] = useState(siteStartingValue);
	const [type, setType] = useState(typeStartingValue);
	const [region, setRegion] = useState(regionStartingValue);
	const [rsm, setRsm] = useState(rsmStartingValue);
	const [psec, setPsec] = useState(psecStartingValue);
	const [protectiveGroup, setProtectiveGroup] = useState(protectiveGroupStartingValue);
	const [state_province, setStateProvince] = useState(state_provinceStartingValue);
	const [lastModified, setLastModified] = useState(lastModifiedStartingValue);
	const [dateCreated, setDateCreated] = useState(dateCreatedStartingValue);
	const { token } = useContext(RoleContext);
	const { EffectivenessDefinitions } = useContext(DefinitionsContext);
	const [healthCheckResults, setHealthCheckResults] = useState<HealthCheckRowType[]>([]);
	const [refreshCounterForSelectedSite, setRefreshCounterForSelectedSite] = useState(0);

	function setSelectedLocation(location: EntityType) {
		setSelectedEntity(location);
		setAddress(location.address);
		setAor(location.aor);
		setAsm(location.ASM);
		setCarverAssessment(location.carver_assessment);
		setCarverAccessibility(location.carver_accessibility);
		setCarverCriticality(location.carver_criticality);
		setCarverEffect(location.carver_effect);
		setCarverRecognizability(location.carver_recognizability);
		setCarverRecoverability(location.carver_recoverability);
		setCarverVulnerability(location.carver_vulnerability);
		setCity(location.city);
		setCountry(location.country);
		setIsActive(location.isActive);
		setLocationType(location.locationType);
		setSite(location.site);
		setType(location.type);
		setRegion(location.region);
		setRsm(location.RSM);
		setPsec(location.PSEC);
		setProtectiveGroup(location.protectiveGroup);
		setStateProvince(location.state_province);
		setLastModified(location.lastModified);
		setDateCreated(location.dateCreated);
		setConfidentialOrRestricted(location.confidentialOrRestricted);

	}


	useEffect(() => {
		const fxn = async () => {
			if (selectedEntity.site !== "") {
				const res = await secureFetch(`${getBaseUrl()}/healthCheck?siteName=${selectedEntity.site}`,
					'GET' );
				if (res.status !== 200) {

					return;
				}
				const json = await res.json();
				const adjustedHealthCheck = json.healthCheck.map((hcDB: HealthCheckDBType) => {
					return {
						...hcDB,
						effectiveness: EffectivenessDefinitions[hcDB.effectiveness]?.name,
						controls_count: hcDB.controls_used + " / " + hcDB.controls_count,
						risk_category_name: hcDB.risk_name_full,
						risk_name: hcDB.sub_risk_full_name
					};
				})
				setHealthCheckResults(adjustedHealthCheck);
			}
		}
		fxn();


	}, [selectedEntity.entityId]);


	const putChanges = async () => {
		const res = await secureFetch(`${getBaseUrl()}/entities`, 
			 "PUT", 
			 JSON.stringify({
				entityId: selectedEntity.entityId,
				aor,
				consequenceSet: "",
				isActive,
				confidentialOrRestricted,
				site,
				type,
				region,
				country,
				RSM: rsm,
				ASM,
				PSEC: psec,
				protectiveGroup,
				dateCreated,
				lastModified,
				state_province,
				city,
				address,
				locationType,
				contextStatement: "",
				protectiveRequirements: "",
				site_risk_avg: 0,//these next three are ignored in the API handler
				i90: 0, //num of incidents in the last 90 days
				avg_effect: 0,
				carver_assessment: carverAssessment,
				carver_accessibility: carverAccessibility,
				carver_criticality: carverCriticality,
				carver_effect: carverEffect,
				carver_recognizability: carverRecognizability,
				carver_vulnerability: carverVulnerability,
				carver_recoverability: carverRecoverability,
				survey_completed: 0,
				survey_completed_date: "",
				survey_partially_completed: 0,
				survey_partially_completed_date: ""
			}));

		
		if (res.status === 200) {
			setRefreshCounterForSelectedSite((oldVal: number) => (oldVal + 1))
			setLastModified(new Date().toDateString())
		} else {
			console.log(res);
		}

		return res.status === 200;
	};

	const postChanges = async (ent: EntityType) => {
		const res = await secureFetch(`${getBaseUrl()}/entities`, "POST", JSON.stringify(ent) );

		const json = await res.json();
		if (res.status === 200) {
			setRefreshCounterForSelectedSite((oldVal: number) => (oldVal + 1))
		} else {
			console.log(res);
		}

		return res.status === 200;
	};


	return <SelectedSiteContext.Provider value={{
		address,
		aor,
		ASM,
		carverAssessment, setCarverAssessment,
		carverAccessibility, setCarverAccessibility,
		carverCriticality, setCarverCriticality,
		carverEffect, setCarverEffect,
		carverRecognizability, setCarverRecognizability,
		carverVulnerability, setCarverVulnerability,
		carverRecoverability, setCarverRecoverability,
		city,
		dateCreated,
		confidentialOrRestricted,
		country,
		isActive,
		lastModified,
		locationType,
		site,
		type,
		region,
		rsm,
		psec,
		protectiveGroup,
		state_province,
		healthCheckResults,
		selectedEntity,
		setSelectedLocation,
		isEditing,
		setIsEditing,
		setAddress,
		setAor,
		setAsm,
		setCity,
		setDateCreated,
		setConfidentialOrRestricted,
		setCountry,
		setIsActive,
		setLastModified,
		setLocationType,
		setSite,
		setType,
		setRegion,
		setRsm,
		setPsec,
		setProtectiveGroup,
		setStateProvince,
		postChanges,
		putChanges,
		refreshCounterForSelectedSite,
	}}>{props.children}</SelectedSiteContext.Provider>;
}

export { SelectedSiteContext, SelectedSiteContextProvider }