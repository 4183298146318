import React, { useContext, useEffect, useState } from 'react';

import { MbmProvider } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from '../constants';
import LayoutWrapper from './LayoutWrapper'

import { RoleContext } from './RoleProvider';
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from '../helpers';


const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
        .withLocale(DEFAULT_LOCALE)
        .withDefaultLocale(DEFAULT_LOCALE)
        .build(),
    resolveBundleUrl: (
        resourcePackPath: string | undefined,
        locale: string,
        bundle: string
    ) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

const App = () => {
    const { authenticated, errorMessage, authorizedToViewApp } = useContext(RoleContext);

    if (authenticated && authorizedToViewApp) {
        return (<MbmProvider {...mbmOptions}>
          <LayoutWrapper />
        </MbmProvider>)
    } else {
        return (<div > {errorMessage === '' ? "Loading...." : <div className="unauthorized"><div>{errorMessage}</div></div>}</div>)
    }
}




export default App;
