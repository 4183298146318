import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Header,
    Select,
    Input,
    SelectProps,
    RadioGroup,
    FormField,
    Textarea,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { EntityAndManagerContext } from "../../EntityAndManagerProvider";
import { ProtectiveGroups, emptyLocation, RegionOptions } from "../../../constants";
import { SecurityManagerType } from "../../../types";
import { SelectedSiteContext } from "../../useSelectedSite";
import { SiteContext } from "../useNewTabbedView";


const SiteInfoEditMode = () => {

    const { selectedEntity,
        address,
        setAddress,
        aor,
        setAor,
        ASM,
        setAsm,
        city,
        setCity,
        confidentialOrRestricted,
        setConfidentialOrRestricted,
        country,
        setCountry,
        isActive,
        setIsActive,
        lastModified,
        setLastModified,
        locationType,
        setLocationType,
        site,
        setSite,
        setSelectedLocation,
        type,
        setType,
        region,
        setRegion,
        rsm,
        setRsm,
        psec,
        setPsec,
        protectiveGroup,
        setProtectiveGroup,
        state_province,
        setStateProvince,
        setIsEditing,
        putChanges,
    } = useContext(SelectedSiteContext);

    const { setInTabbedView  } = useContext(SiteContext);
    const { aors,
        selectedRegion,//I think I need to move this to useSelectedSite
        setSelectedRegion,//I think I need to move this to useSelectedSite
        countries,
        selectedAOR,//I think I need to move this to useSelectedSite
        setSelectedAOR,//I think I need to move this to useSelectedSite
        managers,
        refreshEntitiesAndManagers } = React.useContext(EntityAndManagerContext);


    const [selectedTypeOption, setSelectedTypeOption] = useState<SelectProps.Option>({});
    const [selectedRegionOption, setRegionOption] = useState<SelectProps.Option>({});
    const [selectedProtectiveGroupOption, setSelectedProtectiveGroupOption] = useState<SelectProps.Option>({});
    const [selectedRSMOption, setSelectedRSMOption] = useState<SelectProps.Option>({});
    const [selectedASMOption, setSelectedASMOption] = useState<SelectProps.Option>({});
    const [selectedAorOption, setSelectedAorOption] = useState<SelectProps.Option>({});
    const [selectedCountryOption, setSelectedCountryOption] = useState<SelectProps.Option>({});
    const [selectedLocationTypeOption, setSelectedLocationTypeOption] = useState<SelectProps.Option>({});


    const [isDirty, setIsDirty] = useState(false);
    const protectiveGroupOptions = ProtectiveGroups.map((pg) => ({ label: pg, value: pg }));
    let ASMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
    let RSMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
    let AOROptions = aors.map((x) => ({ label: x, value: x }));
    AOROptions.unshift({ label: "Select AOR", value: "" });
    let countryOptions = countries.map((x) => ({ label: x, value: x }));
    countryOptions.unshift({ label: "Select country", value: "" });

    const typeOptions = [
        { label: "Location", value: "location" },
        { label: "Event / Activity", value: "event-activity", disabled: true },
        { label: "Asset / Object", value: "asset-object", disabled: true },
        { label: "Organization", value: "org", disabled: true },
        { label: "Person", value: "person", disabled: true },
    ];

    const locationTypeOptions = [
        { label: "Corporate Location", value: "Corporate" },
        { label: "Subsite Location", value: "Subsite" },
        { label: "Offsite Location", value: "Offsite" },
    ];

    const localCopyOfEntity = { ...selectedEntity };




    // This useEffect's job is to keep the fields in sync while the entity is being loaded
    // There's probably a better way to handle this. If I take out this apparently redundant useEffect, the form is empty eventhough we've got an
    // entity to edit.  See https://stackoverflow.com/questions/58818727/react-usestate-not-setting-initial-value
    useEffect(() => {
        if (isDirty) {
            return;
        }


        if (region !== "") {
            setSelectedRegion(region);
            setRegionOption(RegionOptions.filter((e) => e.value === region)[0]);
        } else {
            setRegionOption(RegionOptions[0]);
            setSelectedAorOption(AOROptions[0]);
            setSelectedCountryOption(countryOptions[0]);
        }
        if (aor !== "") {
            //setSelectedAOR(aor);
            setAor(aor)
            setSelectedAorOption(AOROptions.filter((e) => e.value == aor)[0]);
        }

        ASMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));
        RSMOptions = managers.map((x: SecurityManagerType) => ({ label: x.full_name, value: x.alias }));

        countryOptions = countries.map((x) => ({ label: x, value: x }));
        if (country !== "") {
            setSelectedCountryOption(countryOptions.filter((e) => e.value == country)[0])
        }

        setSelectedTypeOption(typeOptions.filter((e) => e.value == type)[0]);
        setSelectedLocationTypeOption(locationTypeOptions.filter((e) => e.value == locationType)[0]);
        setSelectedRSMOption(RSMOptions.filter((e: SelectProps.Option) => e.value == rsm)[0]);
        setSelectedASMOption(ASMOptions.filter((e: SelectProps.Option) => e.value == ASM)[0]);
        setSelectedProtectiveGroupOption(protectiveGroupOptions.filter((e) => e.value == protectiveGroup)[0])

    }, [selectedEntity.site, selectedRegion, managers[0], selectedEntity.entityId]);

    useEffect(() => {
        if (isDirty) {
            setSelectedAorOption(AOROptions[0]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (isDirty) {
            setSelectedCountryOption(countryOptions[0]);
        }
    }, [selectedAOR]);

    const allFieldsAreValid = () => {
        return site.length > 0
            && selectedTypeOption !== undefined
            && selectedProtectiveGroupOption !== undefined
            && selectedRegionOption.value !== ""
            && selectedAorOption.value !== ""
            && selectedCountryOption.value !== ""
            && state_province.length > 0
            && city.length > 0
            && address.length > 0
            && selectedRSMOption !== undefined
            && selectedASMOption !== undefined
            && psec.length > 0
            && selectedLocationTypeOption !== undefined;
    }

    return (<div className="body">
        <div className="modal-body">
            <div className="admin-section">
                <div id="entity-status">
                    <label><strong>Status:</strong></label>
                    <fieldset className="radio-fieldset mt-6">
                        <label htmlFor="active">
                            <input
                                type="radio"
                                id="active"
                                name="isActive"
                                checked={isActive === 1}
                                onChange={(evt) => {
                                    setIsDirty(true);
                                    setIsActive(evt.target.value === "on" ? 1 : 0)
                                }}
                            />
                            Active
                        </label>
                        <label htmlFor="inactive">
                            <input
                                type="radio"
                                id="inactive"
                                name="activity"
                                checked={isActive === 0}
                                onChange={(evt) => {
                                    setIsDirty(true);
                                    setIsActive(evt.target.value === "on" ? 0 : 1)
                                }}
                            />
                            Inactive
                        </label>
                    </fieldset>
                </div>
            </div>

            <div className="entity-info">
                <FormField label="Entity Type:">
                    <Select
                        selectedOption={selectedTypeOption}
                        placeholder="Select type"
                        onChange={({ detail }) => {
                            setIsDirty(true);
                            setSelectedTypeOption(detail.selectedOption);
                            setType(detail.selectedOption.value!);
                        }}
                        options={typeOptions}
                    />
                </FormField>
            </div>

            <div className="entity-info">
                <FormField label="Protective Group:">
                    <Select
                        selectedOption={selectedProtectiveGroupOption}
                        placeholder="Select Protective group"
                        onChange={({ detail }) => {
                            setIsDirty(true);
                            setSelectedProtectiveGroupOption(detail.selectedOption);
                            setProtectiveGroup(detail.selectedOption.value!)
                        }}
                        options={protectiveGroupOptions}
                    />
                </FormField>
            </div>

            <div>
                <strong>Confidential or restricted view:</strong>
                <fieldset className="radio-fieldset">
                    <label htmlFor="isConfidential">
                        <input
                            type="radio"
                            id="isConfidential"
                            checked={confidentialOrRestricted === 1}
                            onChange={(evt) => {
                                setIsDirty(true);
                                setConfidentialOrRestricted(evt.target.value === "on" ? 1 : 0)
                            }}
                        />
                        Yes
                    </label>
                    <label htmlFor="notConfidential">
                        <input
                            type="radio"
                            id="notConfidential"
                            checked={confidentialOrRestricted === 0}
                            onChange={(evt) => {
                                setIsDirty(true);
                                setConfidentialOrRestricted(evt.target.value === "on" ? 0 : 1)
                            }}
                        />
                        No
                    </label>


                </fieldset>
            </div>

            <div className="entity-additional-info">
                {/* Location Entity Additional Fields form, should connect to the Entity input item to display, when selected above should set "active" class */}
                <div className="additional-fields active" id="location-entity">
                    <div id="location-info">
                        <h3 className="title">Location</h3>

                        <span className="title"><strong>Region</strong></span>
                        <div className="filter-style" id="asset-filter-dropdown">
                            <Select
                                selectedOption={selectedRegionOption}
                                placeholder="Select region"
                                onChange={({ detail }) => {
                                    setIsDirty(true)
                                    setRegionOption(detail.selectedOption)
                                    setSelectedRegion(detail.selectedOption.value!);
                                    setRegion(detail.selectedOption.value!);
                                }}
                                options={RegionOptions}
                            />
                        </div>

                        <span className="title">AOR</span>
                        <div className="filter-style" id="asset-filter-dropdown">
                            <Select
                                selectedOption={selectedAorOption}
                                placeholder="Select AOR"
                                onChange={({ detail }) => {
                                    setIsDirty(true)
                                    setSelectedAorOption(detail.selectedOption);
                                    setSelectedAOR(detail.selectedOption.value!)
                                    setAor(detail.selectedOption.value!);
                                }}
                                options={AOROptions}
                            />

                        </div>

                        <span className="title"> <strong>Country</strong></span>
                        <div className="filter-style" id="asset-filter-dropdown">
                            <Select
                                selectedOption={selectedCountryOption}
                                placeholder="Select Country"
                                onChange={({ detail }) => {
                                    setIsDirty(true);
                                    setSelectedCountryOption(detail.selectedOption);
                                    setCountry(detail.selectedOption.value!)
                                }

                                }
                                options={countryOptions}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <FormField
                        label="State or Province: "
                    >
                        <Input
                            type="text"
                            onChange={(e) => {
                                setIsDirty(true);
                                setStateProvince(e.detail.value);
                            }}
                            value={state_province}
                        />
                    </FormField>
                </div>

                <div>
                    <FormField
                        label="City:"
                    >
                        <Input
                            type="text"
                            onChange={(e) => {
                                setIsDirty(true);
                                setCity(e.detail.value);
                            }}
                            value={city}
                        />
                    </FormField>
                </div>

                <div>
                    <FormField
                        label="Address: "
                    >
                        <Input
                            type="text"
                            onChange={(e) => {
                                setIsDirty(true);
                                setAddress(e.detail.value)
                            }}
                            value={address}
                        />
                    </FormField>
                </div>

                <span className="title"> <strong>RSM</strong></span>

                <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                        selectedOption={selectedRSMOption}
                        placeholder="Select RSM"
                        onChange={({ detail }) => {
                            setIsDirty(true)
                            setSelectedRSMOption(detail.selectedOption);
                            setRsm(detail.selectedOption.value!)
                        }}
                        options={RSMOptions}
                    />
                </div>

                <span className="title"> <strong>ASM</strong></span>
                <div className="filter-style" id="asset-filter-dropdown">
                    <Select
                        selectedOption={selectedASMOption}
                        placeholder="Select ASM"
                        onChange={({ detail }) => {
                            setIsDirty(true);
                            setSelectedASMOption(detail.selectedOption)
                            setAsm(detail.selectedOption.value!);
                        }}
                        options={ASMOptions}
                    />
                </div>

                <FormField label="PSEC">
                    <Input
                        type="text"
                        name="PSEC"
                        value={psec}
                        onChange={(e) => {
                            setIsDirty(true);
                            setPsec(e.detail.value);
                        }}
                    />
                </FormField>
                <FormField label="Location Type:">
                    <Select
                        selectedOption={selectedLocationTypeOption}
                        placeholder="Select location type"
                        onChange={({ detail }) => {
                            setIsDirty(true);
                            setSelectedLocationTypeOption(detail.selectedOption);
                            setLocationType(detail.selectedOption.value!)
                        }}
                        options={locationTypeOptions}
                    />
                </FormField>
            </div>
        </div>
        <div>
            <Box float="right" padding={{ top: "m" }}>
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={(_event) => {
                        setIsDirty(false);
                        setIsEditing(false);
                    }}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={(_event) => {
                            if (allFieldsAreValid()) {
                                putChanges();
                                setIsEditing(false);
                                setIsDirty(false);
                                refreshEntitiesAndManagers();
                                if(!isActive){
                                    setInTabbedView(false);
                                }
                            }

                        }}
                    >
                        Save
                    </Button>
                </SpaceBetween>
            </Box>
        </div>
    </div>)


}

export default SiteInfoEditMode;