import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Modal,
    SpaceBetween,
    Select,
    SelectProps,
    Form,
    FormField,
    Input,
    Header,
} from "@amzn/awsui-components-react";

import { RegionsAorsCountriesContext } from "./useRegionsAorsCountries";
import { FlashMessageContext } from "../useFlashMessages";
import { EmptyManager, ManagerRoleOptions, RegionOptions } from "../../constants";
import { ModalContext } from "../useModal";
import CancelWarning from "../CancelWarning";
import { EditFormProps } from "../../types";

const EditSecurityManagerModal = (props: EditFormProps) => {
    const { aors, putChanges, regionsAorsAndCountriesAreFetched, selectedRegion, updateRegion } = useContext(RegionsAorsCountriesContext)
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const [showChangesWarning, setShowChangesWarning] = useState(false);
    const [hasSeenUnsavedChangesWarning, setHasSeenUnsavedChangesWarning] = useState(false);
    const { hideModals, isShowing, selectedSecurityManager, setSelectedSecurityManager } = useContext(ModalContext);
    const [selectedRegionOption, setRegionOption] = useState<SelectProps.Option>(RegionOptions[0]);
    const [AorOptions, setAorOptions] = useState<SelectProps.Option[]>([]);
    const [selectedAOROption, setAOROption] = useState<SelectProps.Option>({ label: "Select AOR", value: "" });
    const [fullName, setFullName] = useState("");
    const [alias, setAlias] = useState("");
    const [selectedRoleOption, setSelectedRoleOption] = useState<SelectProps.Option>({ label: "Select Role", value: "" });
    const [rollUp, setRollUp] = useState("");
    const {setShowSuccess, setFlashMessage} = useContext(FlashMessageContext);

    //Keep the region DDL up to date
    useEffect(() => {
        if (selectedSecurityManager?.region !== "") {
            setRegionOption(RegionOptions.find((rf) => rf.value == selectedSecurityManager?.region)!);
            updateRegion(selectedSecurityManager?.region!)
        } else {
            setRegionOption(RegionOptions[0]);
        }

        setFullName(selectedSecurityManager!.full_name);
        setAlias(selectedSecurityManager!.alias);
        if (selectedSecurityManager?.role !== "") {
            setSelectedRoleOption(ManagerRoleOptions.find((rf) => rf.value == selectedSecurityManager?.role)!);
        } else {
            setSelectedRoleOption(ManagerRoleOptions[0]);
        }
        setRollUp(selectedSecurityManager!.roll_up);
    }, [selectedSecurityManager!.id]);

    useEffect(() => {
        if (regionsAorsAndCountriesAreFetched && selectedSecurityManager?.region !== "") {
            updateRegion(selectedSecurityManager?.region!)
        }
    }, [regionsAorsAndCountriesAreFetched])

    useEffect(() => {
        if (regionsAorsAndCountriesAreFetched) {
            let newAOROptions = aors.map((x) => ({ label: x, value: x }));
            newAOROptions.unshift({ label: "Select AOR", value: "" });
            setAorOptions(newAOROptions)
            if (selectedSecurityManager?.aor !== "") {
                setAOROption(newAOROptions.find((rf) => rf.value == selectedSecurityManager?.aor)!);
            } else {
                setAOROption(newAOROptions[0]);
            }
        }
    }, [selectedSecurityManager!.id, selectedRegion, regionsAorsAndCountriesAreFetched, aors[0], aors[1]]);


    const resetWarningState = () => {
        setHasPendingChanges(false);
        setShowChangesWarning(false);
        hideModals();
        setHasSeenUnsavedChangesWarning(false);
        setSelectedSecurityManager(EmptyManager);
    }

    return (
        <Modal
            onDismiss={() => hideModals()}
            visible={isShowing("editSecurityManager")}
           size="large"
            header={ <Header>
                <h2>Edit Manager Information</h2>
                    <CancelWarning showWarning={showChangesWarning} warningText="You have made changes that aren't saved. Click &quot;save&quot; to keep your changes or &quot;cancel&quot; to discard them."/>
                </Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={(_event) => {
                            if (hasPendingChanges && !hasSeenUnsavedChangesWarning) {
                                setShowChangesWarning(hasPendingChanges);
                                setHasSeenUnsavedChangesWarning(true);
                                return false;
                            }
                            resetWarningState();
                        }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={async (_event) => {
                                const updatedSecurityManager = {
                                    ...selectedSecurityManager,
                                    id: selectedSecurityManager!.id!,
                                    full_name: fullName,
                                    region: selectedRegion,
                                    alias: alias,
                                    aor: selectedAOROption.value!,
                                    role: selectedRoleOption.value!,
                                    roll_up: rollUp
                                }
                                const result = await putChanges(updatedSecurityManager);
                                if(result == 200){
                                    props.onSuccessfulSave("")
                                }
                                resetWarningState();
                            }}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Form>
                <FormField label="Region">
                    <Select
                        data-testid="ddl-region"
                        selectedOption={selectedRegionOption}
                        placeholder="Select region"
                        onChange={({ detail }) => {
                            setRegionOption(detail.selectedOption)
                            updateRegion(detail.selectedOption.value!);
                        }}
                        options={RegionOptions}
                    />
                </FormField>

                <FormField label="AOR">
                    <Select
                        data-testid="ddl-aor"
                        selectedOption={selectedAOROption}
                        placeholder="Select AOR"
                        onChange={({ detail }) => {
                            setAOROption(detail.selectedOption)
                        }}
                        options={AorOptions}
                    />
                </FormField>

                <FormField label="Full Name">
                    <Input
                        type="text"
                        name="full_name"
                        onChange={(e) => {
                            setFullName(e.detail.value)
                        }}
                        value={fullName}
                    />
                </FormField>

                <FormField label="Alias">
                    <Input
                        type="text"
                        name="alias"
                        onChange={(e) => {
                            setAlias(e.detail.value)
                        }}
                        value={alias}
                    />
                </FormField>

                <FormField label="Role">
                    <Select
                        data-testid="ddl-role"
                        selectedOption={selectedRoleOption}
                        placeholder="Select Role"
                        onChange={({ detail }) => {
                            setSelectedRoleOption(detail.selectedOption)
                        }}
                        options={ManagerRoleOptions}
                    />
                </FormField>

                <FormField label="Roll-Up">
                <Input
                        type="text"
                        name="rollup"
                        onChange={(e) => {
                            setRollUp(e.detail.value)
                        }}
                        value={rollUp}
                    />
                </FormField>
            </Form>
        </Modal>
    );
};

export default EditSecurityManagerModal;
