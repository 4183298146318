import { useContext, useEffect, useState } from "react";
import {Navigate} from "react-router-dom";
import RiskRow from "./RiskRow";
import { RiskContext } from "../RiskProvider";
import { RoleContext } from "../RoleProvider";
import {ModalContext} from "../useModal";
import { Button } from "@amzn/awsui-components-react";
import EmptyState from "../EmptyState";

const RiskTable = () => {
   const { state, getControlSet, createRisk, updateRisk, dispatch } = useContext(RiskContext);
  const { showModal } = useContext(ModalContext);
  const { roleFetchComplete, userIsRiskAdmin} = useContext(RoleContext);

  const riskRows = state.risks.map((risk) => {
    const subRisksRows = state.subRisks.filter((sr) => {
      return sr.risk_id === risk.risk_number;
    });
    return (
      <RiskRow
        key={risk.risk_number + "_key"}
        risk={risk}
        subRisks={subRisksRows}
      />
    );
  });

  if(!roleFetchComplete) {
    return <EmptyState title="Loading risks..." subtitle="" action="" />
  }

  return (
    <div>
      {riskRows}
    </div>
  );
};

export default RiskTable;
