import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { ModalContext } from "../useModal";
import { ControlDataContext } from "../ControlsProvider";
import { NameAndIdType } from "src/types";
import { EmptyControl } from "src/constants";

const ControlDetailsModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  const { allControls, controlCategories, subControls, controlOwners, selectedControlID } = useContext(ControlDataContext);

  const theControl = allControls.find((c) => c.id == selectedControlID) || EmptyControl;
  const {
    control_name,
    category_type_id,
    generalDescription,
    status,
    owner_id,
    deter,
    detect,
    delay,
    respond,
    recover,
  } = theControl;

  const tempDefenseOptions = [];
  if (deter === 1) {
    tempDefenseOptions.push("deter")
  }
  if (delay === 1) {
    tempDefenseOptions.push("delay")
  }
  if (detect === 1) {
    tempDefenseOptions.push("detect")
  }
  if (respond === 1) {
    tempDefenseOptions.push("respond")
  }
  if (recover === 1) {
    tempDefenseOptions.push("recover");
  }

  const ownerName = controlOwners.find((co: NameAndIdType) => (co.id === owner_id))?.name || "";
  const performanceCriteria = subControls.filter((sc) => {
    return parseInt(sc.controlID) === selectedControlID && sc.isActive == "1";
  } ).map((subControl) => (<li className={subControl.isActive ? "" : "inactive"} key={subControl.id}>{subControl.name}</li>));

  return (
    <Modal
      header={control_name}
      onDismiss={() => hideModals()}
      visible={isShowing("controlDetailsModal")}
    >
        <div className="control-details-modal">
          <div className="admin-section">
            <div className="space-above"><strong>Category type: </strong>{controlCategories.find((cc) => (cc.id === category_type_id))?.name || ""}</div>
            <div className="space-above"><strong>Control name: </strong>{control_name}</div>
            <div className="space-above"><strong>General description:</strong>
              <div>{generalDescription}</div>
            </div>
            <div className="space-above"><strong>Owner: </strong>{ownerName}</div>
            <div className="space-above"><strong>Protection planning: </strong>{tempDefenseOptions.join(", ")} </div>
            <div className="space-above"><strong>Performance criteria: </strong>{performanceCriteria.length === 0 && "none"}</div>
            {performanceCriteria.length > 0 && <ul>
              {performanceCriteria}
            </ul>
            }
            <div className="space-above"><strong>Status: </strong>{status}</div>
          </div>
        </div>
    </Modal >
  );
};

export default ControlDetailsModal;
