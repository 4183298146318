import React, { useContext, useState } from "react";
import { SelectedSiteContext } from "../../useSelectedSite";
import { prettyDateString } from "src/utils";

const SiteInfoReadOnly = () => {
    const { 
        address,
		aor,
		ASM,
		city,
		dateCreated,
		confidentialOrRestricted,
		country,
		isActive,
		lastModified,
		locationType,
		site,
		type,
		region,
		rsm,
		psec,
		protectiveGroup,
		state_province,
     } = useContext(SelectedSiteContext);

    return (<>
        <div className="admin-section">
            <div id="entity-status">
                Status:
                <label htmlFor="active">
                    <input
                        type="radio"
                        id="active"
                        name="isActive"
                        checked={isActive === 1}
                        disabled={true}
                    />
                    Active
                </label>
                <label htmlFor="inactive">
                    <input
                        type="radio"
                        id="inactive"
                        name="activity"
                        checked={isActive === 0}
                        disabled={true}
                    />
                    Inactive
                </label>
            </div>
        </div>
        <div className="entity-info">
            <div id="entity-type">Entity Type: {type}</div>
        </div>
        <div className="entity-info">
            <div>Protective Group: {protectiveGroup}</div>
        </div>
        <div>
            <div id="entity-status">
                Confidential or Restricted:
                <label htmlFor="confidentialOrRestrictedTrueState">
                    <input
                        type="radio"
                        id="confidentialOrRestrictedTrueState"
                        name="confidentialOrRestrictedTrueState"
                        value={confidentialOrRestricted}
                        checked={confidentialOrRestricted === 1}
                        disabled
                    />
                    Yes
                </label>
                <label htmlFor="confidentialOrRestrictedFalseState">
                    <input
                        type="radio"
                        id="confidentialOrRestrictedFalseState"
                        name="confidentialOrRestrictedFalseState"
                        checked={confidentialOrRestricted === 0}
                        value={confidentialOrRestricted - 1}
                        disabled
                    />
                    No
                </label>
            </div>
        </div>

        <div className="entity-additional-info">
            {/* Location Entity Additional Fields form, should connect to the Entity input item to display, when selected above should set "active" class */}
            <div className="additional-fields active" id="location-entity">
                <div id="location-info">
                    <h3 className="title">Location</h3>

                    <div className="title">Region: {region} </div>
                    <div className="title">AOR: {aor} </div>
                    <div className="title">Country: {country}</div>
                    <div className="title">State or province: {state_province}</div>
                    <div className="title">City: {city}</div>
                    <div className="title">Address: {address}</div>
                    <div className="title">Site: {site}</div>
                    <div className="title">RSM: {rsm}</div>
                    <div className="title">ASM: {ASM}</div>
                    <div className="title">PSEC: {psec}</div>
                    <div className="title">Location type: {locationType}</div>
                    <div className="title">
                        Created: {prettyDateString(dateCreated)}
                    </div>
                    <div className="title">
                        Last updated: {prettyDateString(lastModified)}
                    </div>
                </div>
            </div>
        </div>
    </>)


}

export default SiteInfoReadOnly;