import React, { useContext} from "react";
import { RiskType, SubRiskType } from "../../types";
import { RiskContext } from "../RiskProvider";
import { ModalContext } from "../useModal";
import {
  ExpandableSection,
  Table,
  Button,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import EmptyState from "../EmptyState";
import { prettyDateString } from "../../utils";

export type RiskRowPropType = { risk: RiskType; subRisks: SubRiskType[] };

const RiskRow = ({ risk, subRisks }: RiskRowPropType) => {
  const { dispatch, getControlSet } = useContext(RiskContext);
  const { showModal } = useContext(ModalContext);
  const { risk_name_full } = risk;

  const {
    items: itemsFromUseCollection,
  } = useCollection(subRisks, {
    filtering: {
      empty: (
        <EmptyState
          title="No Entities Found"
          action={<Button>Create Entity</Button>}
        />
      )
    }
  });
  return (
    <ExpandableSection variant="container" headerText={risk_name_full} onChange={(_e) => {
      dispatch({ Type: "SET_SELECTED_RISK_CATEGORY", Cargo: { riskCategoryId: risk.risk_number } });
    }}>
      <Table
        columnDefinitions={[
          {
            id: "sub_risk_name",
            header: "Risk name",
            cell: (item: SubRiskType) =>  item.sub_risk_name,
            isRowHeader: true,
            width: 225,
          },
          {
            id: "risk_description",
            header: "Description",
            cell: (item: SubRiskType) => item.description,
            isRowHeader: true,
            maxWidth: 450,
            width: 400,
          },
          {
            id: "control_set",
            header: "Control set",
            cell: (item) => (
              <Button variant="link" ariaLabel="Show control set" onClick={(_e) => {
                getControlSet(item.acs_risk_dim_sk);
                dispatch({ Type: "SET_SELECTED_SUBRISK", Cargo: { acs_risk_dim_sk: item.acs_risk_dim_sk } })
                showModal("controlSetForRiskModal");
              }}>
                View control set
              </Button>
            ),
            width: 200,
          },
          {
            id: "sub_risk_created",
            header: "Created",
            cell: (e: SubRiskType) => prettyDateString(e.edw_created_date),
            width: 125,
          },
          {
            id: "sub_risk_lastmod",
            header: "Last modified",
            cell: (e: SubRiskType) => prettyDateString(e.last_updated),
            width: 175,
          },
        ]}
        columnDisplay={[
          { id: "sub_risk_name", visible: true },
          { id: "risk_description", visible: true },
          { id: "control_set", visible: true },
          { id: "sub_risk_created", visible: true },
          { id: "sub_risk_lastmod", visible: true },
        ]}
        items={itemsFromUseCollection}
        wrapLines={true}
      />
    </ExpandableSection>

  );
};

export default RiskRow;
