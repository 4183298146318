import React, { useContext } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { ModalContext } from "../useModal";
import { RiskContext } from "../RiskProvider";
import { ControlDataContext } from "../ControlsProvider";
import { Table } from "@amzn/awsui-components-react";
import { RiskControlCriticalityDisplayType } from "../../types";
import { DefinitionsContext } from "../DefinitionsProvider";

const ControlSetForRiskModal = () => {
  const { hideModals, isShowing } = useContext(ModalContext);
  const { state } = useContext(RiskContext);
  const { CriticalityDefinitions } = useContext(DefinitionsContext);
  const risk = state.selectedSubRisk;
  const { getControlInfo } = useContext(ControlDataContext)
  const controlCriticalities = state.controlCriticalitiesByRisk[risk.acs_risk_dim_sk];

  const controlInfoRows = controlCriticalities?.map((cc) => {
    const extraControlInfo = getControlInfo(cc.control_id);
    return {
      control_id: cc.control_id,
      control_name: extraControlInfo.controlName,
      criticality_name: `${cc.criticality} - ${CriticalityDefinitions[cc.criticality].name}`,
      description: CriticalityDefinitions[cc.criticality].description,
      owner_name: extraControlInfo.owner,
    }
  }) || [{
    control_id: -1,
    control_name: "",
    criticality_name: "",
    description: "",
    owner_name: ""
  }];


  const { items: itemsFromUseCollection, collectionProps } = useCollection(controlInfoRows, {
    sorting: {},
    selection: {},
  });

  return (
    <Modal
      header={`Control set for ${state.selectedSubRisk.sub_risk_name}`}
      onDismiss={() => hideModals()}
      size="large"
      visible={isShowing("controlSetForRiskModal")}
    >
      <div className="control-details-modal">
        <Table
          {...collectionProps}
          columnDefinitions={[
            {
              id: "control_name",
              header: <span data-testid="category-type-header">Control</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.control_name}</span>),
              sortingField: "control_name",
              isRowHeader: true,
              width: 200,
            },
            {
              id: "owner_name",
              header: <span>Owner</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.owner_name}</span>),
              sortingField: "owner_name",
              isRowHeader: true,
              width: 150,
            },
            {
              id: "criticality_name",
              header: <span>Criticality</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.criticality_name}</span>),
              sortingField: "criticality_name",
              isRowHeader: true,
              width: 200,
            },
            {
              id: "description",
              header: <span>Description</span>,
              cell: (e: RiskControlCriticalityDisplayType) => (<span>{e.description}</span>),
              sortingField: "description",
              isRowHeader: true,
              width: 250,
              maxWidth: 300
            },
          ]}
          columnDisplay={[
            { id: "control_name", visible: true },
            { id: "owner_name", visible: true },
            { id: "criticality_name", visible: true },
            { id: "description", visible: true }
          ]}
          enableKeyboardNavigation
          items={itemsFromUseCollection}
          loadingText="Loading controls"
          wrapLines={true}
        />
      </div>
    </Modal >
  );
};

export default ControlSetForRiskModal;
