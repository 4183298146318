import { createContext, useEffect, useState, useContext } from "react";
import { RoleContext } from "./RoleProvider";
import { EmptyControl, EmptySubControl, EmptyControlCategory, EmptyControlOwner } from "../constants";
import { ControlType, SubControlType } from "src/types";
import { getBaseUrl } from "../utils";
import useSecuredFetch from "./useSecuredFetch";


export const ControlDataContext = createContext({
  allControls: [EmptyControl],
  controlCategories: [EmptyControlCategory],
  controlOwners: [EmptyControlOwner],
  createControl: (_c: ControlType) => { },
  getControlInfo: (_controlId: string) => ({ controlName: "", owner: "", description: "" }),
  selectedControlID: 0,
  setSelectedControlID: (n: number) => { },
  subControls: [EmptySubControl],
  updateControl: (_c: ControlType, _subControlsToAdd: SubControlType[], _subControlsToRemove: SubControlType[]) => { },
});

export const ControlProvider = (props: { children: JSX.Element }) => {
  const [controlCategories, setControlCategories] = useState([EmptyControlCategory])
  const [allControls, setAllControls] = useState([EmptyControl]);
  const [subControls, setSubControls] = useState([EmptySubControl]);
  const [controlOwners, setControlOwners] = useState([EmptyControlOwner]);
  const { token } = useContext(RoleContext);
  const [selectedControlID, setSelectedControlID] = useState(0);

  const [
    counterToRefetchDataForSelectedControl,
    setCounterToRefetchDataForSelectedControl,
  ] = useState(0);

  const secureFetch = useSecuredFetch();

  useEffect(() => {
    async function fxn() {
      const response = await secureFetch(`${getBaseUrl()}/controls`, 'GET');

      const {
        allControls,
        subControls,
        allControlCategories,
        controlOwners
      } = await response.json();

      setAllControls(allControls);
      setSubControls(subControls);
      setControlCategories(allControlCategories);
      setControlOwners(controlOwners);


    }
    if (token.length > 1) {
      fxn();
    }

  }, [counterToRefetchDataForSelectedControl, token]);

  const updateControl = async (c: ControlType, subControlsToAdd: SubControlType[], subControlsToRemove: SubControlType[]) => {
    const res = await secureFetch(`${getBaseUrl()}/controls`, 'PUT', JSON.stringify({ c, subControlsToAdd, subControlsToRemove }));
    setCounterToRefetchDataForSelectedControl((oldVal) => oldVal + 1)
  }

  const createControl = async (c: ControlType) => {
    const res = await secureFetch(`${getBaseUrl()}/controls`, "POST", JSON.stringify(c));
    setCounterToRefetchDataForSelectedControl((oldVal) => oldVal + 1)
  }

  const getControlInfo = (controlId: string) => {
    const theControl = allControls.find((sc) => sc.id == parseInt(controlId)) || EmptyControl;
    return {
      controlName: theControl.control_name || '',
      description: theControl.generalDescription || '',
      owner: theControl.owner_name
    };
  };


  return (
    <ControlDataContext.Provider
      value={{
        allControls,
        controlCategories,
        controlOwners,
        createControl,
        getControlInfo,
        selectedControlID,
        setSelectedControlID,
        subControls,
        updateControl,
      }}
    >
      {props.children}
    </ControlDataContext.Provider>
  );
};
