import React, { useContext, useState } from "react";
import { Button, Grid, Header, Icon, SpaceBetween } from "@amzn/awsui-components-react";
import { SelectedSiteContext } from "../../useSelectedSite";
import SiteInfoReadOnly from "./SiteInfoReadOnly";
import SiteInfoEditMode from "./SiteInfoEditMode";
import { ModalContext } from "../../useModal"
import ControlsRegisterModal from "./ControlsRegisterModal";
import RiskRegisterModal from "./RiskRegisterModal";

const SiteLandingPage = () => {
  const { selectedEntity, isEditing, setIsEditing } = useContext(SelectedSiteContext);
  const { site } = selectedEntity;
  const { showModal } = useContext(ModalContext);

  return <div>
    <Grid
      gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}
    >
      <div className="site-landing-page">

        <Header
          variant="h2"
          actions={ isEditing ? undefined :
                (<SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={() => showModal("riskRegisterModal")}>Risk register</Button>
                  <Button variant="link" onClick={() => showModal("controlsRegisterModal")}>Control register</Button>
                </SpaceBetween>)
            }>
          {site} <span style={{ verticalAlign: "bottom" }} onClick={(evt) => setIsEditing(!isEditing)}><Icon name="edit" variant={isEditing ? "disabled" : "subtle"} /></span>
        </Header>


        {isEditing ? <SiteInfoEditMode /> : <SiteInfoReadOnly />}
      </div>
      <div>
        <div className="fake-map"> map goes here </div>
      </div>
    </Grid>
    <ControlsRegisterModal />
    <RiskRegisterModal />
  </div>

}

export default SiteLandingPage;