import React, { createContext, useContext, useEffect, useState } from 'react';
import { EntityType, HealthCheckRowType, HealthCheckDBType, IncidentType, SecurityManagerType } from 'src/types';
import { emptyIncident, emptyLocation, EmptyManager } from '../constants';
import { DefinitionsContext } from "./DefinitionsProvider";
import { RoleContext } from "./RoleProvider";


export type ModalContextType = {
	
	isShowing: (s: string) => boolean;

	
	hideModals: () => void;
	showModal: (s: string) => void;
	
	selectedSecurityManager: SecurityManagerType | null;
	
	
	setSelectedSecurityManager: (mgr: SecurityManagerType) => void;

}

const dummy: ModalContextType = {
	isShowing: (_modalId: string) => false,
	hideModals: () => { },
	
	showModal: (_modalId: string) => { },
	selectedSecurityManager: null,
	setSelectedSecurityManager: (_mgr: SecurityManagerType) => {}
	
}

const ModalContext = createContext<ModalContextType>(dummy);

const ModalContextProvider = (props: { children: JSX.Element }) => {
	
	
	const [currentlyActiveModal, setCurrentlyActiveModal] = useState("");
	//"editing" means that you're working on an existing entity NOT whether your existing entity is in edit mode or view mode
	
	
	
	const [selectedSecurityManager, setSelectedSecurityManager] = useState(EmptyManager);
	



	function hideModals() {
		setCurrentlyActiveModal("");
	}

	function isShowing(modalId: string) {
		return currentlyActiveModal === modalId;
	}

	function showModal(modalId: string) {
		setCurrentlyActiveModal(modalId);
	}


	return <ModalContext.Provider value={{
		isShowing,
		
		hideModals,
		showModal,
		selectedSecurityManager,
		setSelectedSecurityManager
	}}>{props.children}</ModalContext.Provider>;
}

export { ModalContext, ModalContextProvider }